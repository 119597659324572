<template>
  <!-- 钱包详情--提现 -->
  <div class="wrapper">
    <div class="header">
      <span>
        <admin-title :title="$route.meta.name"></admin-title>
      </span>
      <span>
        <el-button type="info" size="mini" @click="$router.back()">返回</el-button>
      </span>
    </div>

    <div class="title">
      <span>待结算账户</span>
    </div>

    <el-descriptions style="margin-bottom:20px;" direction="vertical" :column="6" border>
      <el-descriptions-item label="资金余额" :span="1" label-class-name="my-label">{{ waitSettleAccount.balanceAmount }}</el-descriptions-item>
      <el-descriptions-item label="可用余额" :span="1" label-class-name="my-label">{{ waitSettleAccount.availableAmount }}</el-descriptions-item>
      <el-descriptions-item label="冻结金额" :span="1" label-class-name="my-label">{{ waitSettleAccount.freezeAmount }}</el-descriptions-item>
      <el-descriptions-item label="昨日资金余额" :span="1" label-class-name="my-label">{{
        waitSettleAccount.lastBalanceAmount
      }}</el-descriptions-item>
      <el-descriptions-item label="昨日可用余额" :span="1" label-class-name="my-label">{{
        waitSettleAccount.lastAvailableAmount
      }}</el-descriptions-item>
      <el-descriptions-item label="昨日冻结金额" :span="1" label-class-name="my-label">{{ waitSettleAccount.lastFreezeAmount }}</el-descriptions-item>
    </el-descriptions>

    <div class="title">
      <span>可用账户</span>
      <span>
        <el-button v-checkbtn="isShowBtn(AUTH_BTN.merchant_list_withdraw_done)" type="primary" size="mini" @click="handleWithdraw">提现</el-button>
      </span>
    </div>
    <el-descriptions style="margin-bottom:20px;" direction="vertical" :column="6" border>
      <el-descriptions-item label="资金余额" :span="1" label-class-name="my-label">{{ availableAccount.balanceAmount }}</el-descriptions-item>
      <el-descriptions-item label="可用余额" :span="1" label-class-name="my-label">{{ availableAccount.availableAmount }}</el-descriptions-item>
      <el-descriptions-item label="冻结金额" :span="1" label-class-name="my-label">{{ availableAccount.freezeAmount }}</el-descriptions-item>
      <el-descriptions-item label="昨日资金余额" :span="1" label-class-name="my-label">{{ availableAccount.lastBalanceAmount }}</el-descriptions-item>
      <el-descriptions-item label="昨日可用余额" :span="1" label-class-name="my-label">{{
        availableAccount.lastAvailableAmount
      }}</el-descriptions-item>
      <el-descriptions-item label="昨日冻结金额" :span="1" label-class-name="my-label">{{ availableAccount.lastFreezeAmount }}</el-descriptions-item>
    </el-descriptions>
    <!-- 弹窗 -->
    <el-dialog title="提现" :visible.sync="show" width="width">
      <el-form ref="form" :model="formData" label-width="100px">
        <div v-if="!token">
          <el-form-item label="提现金额">
            <el-input style="width:400px" v-model="formData.withdrawAmount" size="" placeholder="请输入" clearable></el-input>
          </el-form-item>
          <el-form-item label="提现密码">
            <div class="pwd-wrapper">
              <div id="ipt_pwd2" class="ipt_pwd">
                <span id="LOGPASS2_SHOW" ref="place">请输入密码</span>
                <input id="LOGPASS2" class="default input_search" placeholder="" type="password" ref="pwd" disabled="disabled" />
                <div id="keyboardBtn2" class="ipt_pwd_rjp default"></div>
              </div>
            </div>
          </el-form-item>
        </div>
        <div v-else>
          <el-form-item label="交易验证码">
            <el-input style="width:250px" v-model="verifyCode" size="" placeholder="请输入" clearable></el-input>
          </el-form-item>
        </div>
      </el-form>

      <div slot="footer">
        <el-button @click="show = false" size="small">取 消</el-button>
        <el-button type="primary" @click="submit" size="small">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { authBtnMixin } from '@/mixins/authBtnMixin'
import { getAccpDetailAPI, getSecretRandomAPI, handleWithdrawApplyAPI, handleWithdrawVerifyAgainAPI } from './api'
export default {
  name: 'AccpDetail',
  mixins: [authBtnMixin],
  data() {
    return {
      show: false,
      detailInfo: {},
      options: {},
      formData: {
        withdrawAmount: '',
        password: ''
      },
      passwordInstance: null,
      token: '',
      withdrawNo: '',
      verifyCode: ''
    }
  },

  mounted() {
    this.getAccpDetail()
  },
  computed: {
    availableAccount() {
      let obj = {}
      if (this.detailInfo.availableAccount) {
        obj = this.detailInfo.availableAccount
      }
      return obj
    },
    waitSettleAccount() {
      let obj = {}
      if (this.detailInfo.waitSettleAccount) {
        obj = this.detailInfo.waitSettleAccount
      }
      return obj
    }
  },
  methods: {
    // 钱包详情
    async getAccpDetail() {
      this.detailInfo = await getAccpDetailAPI(this.$route.params.id)
    },
    // 提交按钮
    async submit() {
      if (this.token) {
        // 如果有token需二次验证
        const data = {
          withdrawNo: this.withdrawNo,
          token: this.token,
          verifyCode: this.verifyCode
        }
        // handleWithdrawVerifyAgainAPI(data).then(
        //   res => {
        //     // console.log('成功', res)
        //     this.$message.success('提现成功')
        //     this.passwordInstance.clearPWD()
        //     // this.$refs.place.style.display = 'block'
        //     this.getAccpDetail()
        //     this.show = false
        //   },
        //   err => {
        //     // this.passwordInstance.clearPWD()
        //     // this.$refs.place.style.display = 'block'
        //     // this.getAccpDetail()
        //     // this.show = false
        //     // console.log('失败', err)
        //   }
        // )
        await handleWithdrawVerifyAgainAPI(data)
        // 验证成功
        this.$message.success('提现成功')
        this.show = false
        this.passwordInstance.clearPWD()
        this.getAccpDetail()
        // this.$refs.place.style.display = 'block'
        return
      }
      const { withdrawAmount, password, verifyCode } = this.formData
      const reg = /^(([1-9][0-9]*\.[0-9][0-9]*)|([0]\.[0-9][0-9]*)|([1-9][0-9]*)|([0]{1}))$/
      if (!reg.test(withdrawAmount)) {
        this.$message.error('提现金额格式不正确')
        return
      }
      const data = { randomKey: this.options.randomKey, withdrawAmount, password, merchantId: Number(this.$route.params.id) }
      // 提现请求
      const { withdrawNo, token } = await handleWithdrawApplyAPI(data)
      this.token = token || ''
      // this.token = '1'
      this.withdrawNo = withdrawNo || ''
      // this.withdrawNo = '1'
      if (!token) {
        // 无token,提现成功
        this.$message.success('提现成功')
        this.passwordInstance.clearPWD()
        // this.$refs.place.style.display = 'block'
        this.getAccpDetail()
        this.show = false
      }
    },
    // 提现按钮，打开弹窗
    handleWithdraw() {
      this.getSecretRandom()
      this.formData.withdrawAmount = this.detailInfo.availableAccount.availableAmount || 0
      this.formData.password = ''
      this.formData.verifyCode = ''
      this.token = ''
      this.withdrawNo = ''
      this.verifyCode = ''
      this.show = true
    },
    async getSecretRandom() {
      this.options = await getSecretRandomAPI(this.$route.params.id)
      this.passwordInstance = this.initPassword(this.options)
      this.passwordInstance.clearPWD()
      this.$refs.place.style.display = 'block'
    },
    initPassword(options = {}) {
      const passowrd = new showKeyBord({
        png: '//mpay-static.oss-cn-hangzhou.aliyuncs.com/html/lib/state-secret/img', // 图片路径，用这个就行了，也可以下载到本地
        click_objId: '#keyboardBtn2', // 触发显示键盘的Dom节点Id
        regExp1: '^[a-zA-Z0-9]+$', // 输入过程限制的正则，不匹配正则的字符不让输入
        regExp2: '[\\S\\s]{6,12}$', // 输入完成后的正则，与getValid()对应，判断输入值是否匹配该正则
        Input_texId: '#LOGPASS2', // 接收内容的Dom节点Id
        parent_objId: '#ipt_pwd2', // 将键盘插入到何处的Dom节点Id
        placeholderId: '#LOGPASS2_SHOW', // span标签的id，该span用于模拟Input_texId的placeholder
        len: '12', // 表示最大允许输入多少个字符[可以不传递]
        keyBordLeft: '',
        keyBordTop: '',
        chaosMode: 0, // 混乱模式,0:无混乱;1:打开时乱一次;2:每输入一个字符乱一次,默认值0
        clickStatus: 0, // 按键状态,0:鼠标划过、悬浮时按键无变化;1:鼠标划过、悬浮时按键的颜色变化,默认值0
        titlefont: '安全支付输入',
        backfont: '退 格',
        capsfont: '切换大/小写',
        surefont: '确定',
        isen: false,
        callBack: () => {
          this.passwordInstance.setRandKey(this.options.randomValue)
          this.formData.password = this.passwordInstance.getOutputSM()
          console.log('@pass', this.formData.password)
        }, // 密码校验成功回调
        errorCallBack: () => {
          this.passwordInstance.setRandKey(this.options.randomValue)
          this.formData.password = this.passwordInstance.getOutputSM()
        }, // 密码校验失败回调
        rsaPublicKey: options.rsa_public_content, // RSA加密必须参数，国密加密可不要
        sm2KeyHex: options.sm2KeyHex, // 国密加密必须参数，RSA加密可不要
        license: options.license // 必须参数
      })
      passowrd.initOptions()
      return passowrd
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .title {
    margin-bottom: 20px;
    & > span {
      margin-right: 20px;
      font-weight: 600;
    }
  }
  .el-descriptions {
    .my-label {
      color: #1a1e25;
      background: #fafafa;
    }
    .my-content {
      .handle {
        display: flex;
        justify-content: space-between;
        & > span {
          &:first-child {
            margin-right: 20px;
            flex: 1;
          }
        }
      }
    }
  }
  .tip {
    margin-left: 20px;
  }
  .pwd-wrapper /deep/ {
    display: flex;
    flex-direction: column;
    .ipt_pwd {
      position: relative;
      width: 400px;
      height: 40px;
      line-height: 40px;
      margin-bottom: 20px;
      border-radius: 4px;
      border: 1px solid #dcdfe6;
      box-sizing: border-box;
      color: #606266;
      #LOGPASS2_SHOW,
      #LOGPASS3_SHOW {
        color: #c0c4cc;
        padding-left: 13px;
        font-size: 14px;
      }
      .tip {
        position: absolute;
        right: -400px;
        top: 0;
        font-size: 12px;
        color: #8c939d;
        margin-left: 20px;
      }
    }
    #keyboardBtn2 {
      width: 400px;
    }
    .key_main_buttonL {
      .row2 {
        width: 515px !important;
      }
    }
  }
}
</style>
